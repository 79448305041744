import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../../services/authentication.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public user: any;
  public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(public router: Router, private authService: AuthenticationService) {
    this.user = this.authService.currentUserValue;
  }

  ngOnInit() {}

  logout() {
    this.authService.logout();
  }
}
