import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    // {
    //   title: "Simple Page",
    //   icon: "home",
    //   type: "sub",
    //   badgeType: "light-primary",
    //   badgeValue: "2",
    //   active: true,
    //   children: [
    //     { path: "/simple-page/first-page", title: "First Page", type: "link" },
    //     { path: "/simple-page/second-page", title: "Second Page", type: "link" },
    //   ],
    // },
    // { path: "/single-page", icon: "search", title: "Single Page",  active: false, type: "link", bookmark: true },
    {
      path: "/home",
      icon: "home",
      title: "Dashboard",
      active: false,
      type: "link",
    },
    {
      path: "/data-mitra-travel/mitra-travel",
      icon: "verified-user",
      title: "Mitra Travel",
      active: false,
      type: "link",
    },
    {
      path: "/data-mitra-travel/grup-keberangkatan",
      icon: "community",
      title: "Keberangkatan",
      active: false,
      type: "link",
    },
    {
      path: "/data-mitra-travel/peserta",
      icon: "user-crown",
      title: "Peserta",
      active: false,
      type: "link",
    },
    {
      headTitle1: "Data Master",
    },
    {
      path: "/data-master/city",
      icon: "city",
      title: "Kota",
      active: false,
      type: "link",
    },
    {
      path: "/data-master/hotel",
      icon: "building",
      title: "Hotel",
      active: false,
      type: "link",
    },
    {
      headTitle1: "Billing",
    },
    {
      path: "/billing/transaksi",
      icon: "credit-card",
      title: "Transaksi",
      active: false,
      type: "link",
    },
    {
      path: "/billing/penggunaan-kuota",
      icon: "agile",
      title: "Penggunaan Kuota",
      active: false,
      type: "link",
    },
    {
      path: "/billing/paket-kuota",
      icon: "apple-shortcuts",
      title: "Paket Kuota",
      active: false,
      type: "link",
    },
    {
      path: "/billing/promo",
      icon: "percentage",
      title: "Promo",
      active: false,
      type: "link",
    },
    {
      path: "/internal-user",
      icon: "group",
      title: "Internal User",
      active: false,
      type: "link",
    },

    // {
    //   title: "Data Customer",
    //   icon: "user",
    //   type: "sub",
    //   badgeType: "light-primary",
    //   active: false,
    //   children: [
    //     { path: "/data-customer/customer", title: "Customer", type: "link" },
    //     { path: "/data-customer/transaksi", title: "Transaksi", type: "link" },
    //   ],
    // },
    // {
    //   title: "Data Mitra Travel",
    //   icon: "user",
    //   type: "sub",
    //   badgeType: "light-primary",
    //   active: false,
    //   children: [
    //     {
    //       path: "/data-mitra-travel/mitra-travel",
    //       title: "Mitra Travel",
    //       type: "link",
    //     },
    //     {
    //       path: "/data-mitra-travel/paket-umroh",
    //       title: "Paket Umroh",
    //       type: "link",
    //     },
    //     {
    //       path: "/data-mitra-travel/grup-keberangkatan",
    //       title: "Grup Keberangkatan",
    //       type: "link",
    //     },
    //   ],
    // },
    // {
    //   title: "Data Master",
    //   icon: "user",
    //   type: "sub",
    //   badgeType: "light-primary",
    //   active: false,
    //   children: [
    //     { path: "/data-master/user-list", title: "Master Users", type: "link" },
    //     {
    //       path: "/data-master/package-kuota",
    //       title: "Package kuota",
    //       type: "link",
    //     },
    //   ],
    // },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
