import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}

  Login(email: string, password: string) {
    const url = `${environment.apiUrl}/auth/admin/internal`;

    return this.http.post(url, {
      email: email,
      password: password,
    });
  }
}
