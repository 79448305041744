import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/authentication.service";
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          Accept: "application/json",
          Authorization: `Bearer ${currentUser?.jwtToken}`,
        },
      });
    } else if (!currentUser) {
      request = request.clone({
        setHeaders: {
          Accept: "application/json",
        },
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const data = event.body;
          const errCode = [401];
          if (
            errCode.includes(data.err_code) &&
            location.pathname !== "/component" &&
            data.err !== "User tidak ditemukan"
          ) {
            setTimeout(() => {
              this.router.navigate(["auth/login"]);
            }, 1000);
          }
        }
        return event;
      })
    );
  }
}
