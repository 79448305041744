import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../../shared/services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false;
  public errorMessage: any;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    public authService: AuthService
  ) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  login() {
    if (this.loginForm.value["email"] && this.loginForm.value["password"]) {
      this.loading = true;
      this.authService
        .Login(this.loginForm.value["email"], this.loginForm.value["password"])
        .subscribe(
          (res: any) => {
            this.loading = false;
            localStorage.setItem("currentUser", JSON.stringify(res.result));
            // this.router.navigate(["/"]);
            window.location.href = "/";
          },
          (err) => {
            this.loading = false;
            console.log("err", err);
          },
          () => {
            this.loading = false;
            console.log("completed");
            // this.router.navigate(["/"]);
            window.location.href = "/";
          }
        );
    } else {
      console.log("Email and password is required.");
    }
  }
  showPassword() {
    this.show = !this.show;
  }
}
