import { Routes } from "@angular/router";


export const content: Routes = [
  {
    path: "data-mitra-travel",
    loadChildren: () => import("../../components/travel/travel.module").then((m) => m.TravelModule),
  },
  {
    path: "data-customer",
    loadChildren: () => import("../../components/customer/customer.module").then((m) => m.CustomerModule),
  },
  {
    path: "data-master",
    loadChildren: () => import("../../components/data-master/data-master.module").then((m) => m.DataMasterModule),
  },
  {
    path: "billing",
    loadChildren: () => import("../../components/billing/billing.module").then((m) => m.BillingModule),
  },
  {
    path: "internal-user",
    loadChildren: () => import("../../components/internal-user/internal-user.module").then((m) => m.InternalUserModule),
  }
];

